<template>
  <div>
    <header-view></header-view>

    <div class="record">
      <div class="head">
        <div class="title"><span></span>考试记录</div>
        <!-- <div class="btn d-flex align-center cursor">
        <img src="../../assets/img/del_w.png" alt="" />清空记录
      </div> -->
      </div>
      <ul class="list">
        <li class="item" v-for="(item, index) in coursesList" :key="index">
          <div class="order">
            <img src="../../assets/images/live_head.png" alt="" />
          </div>

          <div class="detail">
            <div class="detail-name">
              <div class="detail-title">
                {{ item.examName }}
              </div>
              <div class="detail-time">{{ item.submitTime }}</div>
            </div>
            <!-- <div class="detail-btn">
              {{ item.studyProgress }}
            </div> -->
            <div class="detail-btn" @click="goLive(item)">查看报告</div>
          </div>
        </li>
      </ul>
      <!-- <v-pagination
      v-show="total > display"
      :total="total"
      :current-page="current"
      :display="display"
      @pagechange="pagechange"
    ></v-pagination> -->
      <!-- <div
      v-if="coursesList == '' && isReady"
      class="kong d-flex align-center flex-column justify-center"
    >
      <img class="kong-img" src="../../assets/img/kong.png" alt="" />
      <div class="kong-txt">暂无课程</div>
    </div> -->
    </div>
    <footer-view></footer-view>
    <el-dialog :title="title" :visible.sync="centerDialogVisible" width="80%">
      <div class="report-box">
        <div class="left">
          <div class="score">
            <div class="score-c">
              <div class="score-txt">试卷得分</div>
              <div class="score-num">
                {{ reportInfo.correctScore }}<span>分</span>
              </div>
            </div>
          </div>
          <div class="time"></div>
        </div>
        <div class="right">
          <div class="right-c">
            <div class="items">总题数</div>
            <div class="items-a">{{ reportInfo.questionCount }}</div>
          </div>
          <div class="right-c">
            <div class="items">正确题数</div>
            <div class="items-a">{{ reportInfo.correctCount }}</div>
          </div>
          <div class="right-c">
            <div class="items">总分</div>
            <div class="items-a">{{ reportInfo.questionScore }}</div>
          </div>
          <div class="right-c">
            <div class="items">正确率</div>
            <div class="items-a">{{ reportInfo.correctRate }}</div>
          </div>
        </div>
        <div class="report-btn">
          <div class="btn" @click="determineGo">确定</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { examRecord } from "@/api/user";
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      total: 0, // 记录总条数
      display: 8, // 每页显示条数
      current: 1, // 当前的页数
      coursesList: "",
      reportInfo: {
        correctScore: "",
        questionCount: "",
        correctCount: "",
        correctRate: "",
        questionScore: "",
      },
      title: "",
      centerDialogVisible: false,
    };
  },

  mounted() {
    this.getDetail();
  },
  methods: {
    // 课程列表
    getDetail() {
      examRecord(this.id)
        .then((res) => {
          console.log(res);
          this.coursesList = res.data;
        })
        .catch(() => {});
    },
    determineGo() {
      this.centerDialogVisible = false;
    },
    goLive(item) {
      this.reportInfo.correctScore = item.correctScore;
      this.reportInfo.questionCount = item.questionCount;
      this.reportInfo.correctCount = item.correctCount;
      this.reportInfo.correctRate = item.correctRate;
      this.reportInfo.questionScore = item.questionScore;
      this.centerDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .record {
    width: 100% !important;
    padding: 0 10px 10px 10px !important;
  }
  .list{
    margin-left: -50px;
  }
    .report-box .right{
    flex-wrap: wrap !important;
    width: 100% !important;
  }
}
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.record {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  box-sizing: border-box;
  min-height: calc(100vh - 100px);
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding: 0px 36px 36px 36px;
  box-sizing: border-box;
  .head {
    border-bottom: 1px solid #e7e8ef;
    width: 100%;
    padding: 33px 0 14px 0;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #444444;
      display: flex;
      align-items: center;
      span {
        width: 4px;
        height: 24px;
        background: #5f2eff;
        border-radius: 2px;
        margin-right: 12px;
        display: inline-block;
      }
    }
    .btn {
      font-size: 16px;
      color: #999999;
      margin-left: auto;
      width: 90px;
      img {
        height: 16px;
        width: 16px;
        margin-right: 9px;
      }
    }
  }

  .list {
    .item {
      height: 96px;
      display: flex;
      align-items: center;
      .order {
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 16px;
        margin-right: 30px;
        img {
          position: relative;
        }
        &::before {
          content: "";
          display: block;
          height: 96px;
          width: 2px;
          background: #f4f0ff;
          position: absolute;
          top: 8px;
          left: 7px;
        }
      }

      .detail {
        position: relative;
        padding: 25px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #f6f9ff;
        width: 100%;
        display: flex;
        .detail-name {
          .detail-title {
            font-size: 20px;
            color: #333333;
          }
          .detail-time {
            font-size: 14px;
            color: #999999;
          }
        }
        .detail-btn {
          height: 30px;
          border: 1px solid #ff6923;
          border-radius: 4px;
          font-size: 14px;
          font-weight: bold;
          color: #ff6923;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          cursor: pointer;
          padding: 0 14px;
          box-sizing: border-box;
        }
      }
    }
    :last-child {
      .order::before {
        display: none;
      }
    }
  }
}
.report-box {
  height: 412px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  .left-text {
    height: 68px;
    width: 85px;
    margin-top: 20px;
    margin-left: 27px;
  }
  .right-text {
    height: 31px;
    width: 38px;
    align-self: flex-end;
    margin-bottom: 10px;
    margin-right: 24px;
  }
  .left {
    width: 337px;
    // height: 213px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .score {
      height: 230px;
      width: 230px;
      border-radius: 50%;
      border: 6px solid #f9f9f9;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;

      .score-c {
        width: 200px;
        height: 200px;
        background: #ffffff;
        border: 6px solid #e5e5e5;
        border-radius: 50%;
        box-sizing: border-box;

        .score-txt {
          font-size: 18px;
          color: #292c35;
          display: flex;
          justify-content: center;
          margin-top: 42px;
        }

        .score-num {
          font-size: 70px;
          font-weight: 500;
          color: #ff4e0d;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          margin-top: 5px;

          span {
            font-size: 16px;
            color: #292c35;
            margin-bottom: 14px;
          }
        }
      }
    }

    .time {
      font-size: 14px;
      color: #292c35;
      margin-top: 10px;
      display: flex;
      align-items: flex-end;
      span {
        font-size: 20px;
        color: #ff4e0d;
      }
      .time-txt {
        font-size: 14px;
        color: #292c35;
        margin-top: 5px;
        margin-left: 20px;
      }
    }
  }

  .right {
    width: 500px;
    // height: 213px;
    display: flex;
    .report-msg {
      font-size: 14px;
      color: #ff4e0d;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .right-t {
      display: flex;
      align-items: center;
      margin-top: 66px;

      .item {
        font-size: 16px;
        color: #97979f;
        width: 25%;
        // display: flex;
        // justify-content: center;
      }
    }

    .right-c {
      display: flex;
      align-items: center;
      margin-top: 25px;
      width: 50%;
      justify-content: center;
      .items {
        font-size: 14px;
        color: #292c35;
      }

      .items-a {
        color: #ff4e0d;
        margin-left: 10px;
      }
    }
  }
  .report-btn {
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-top: 45px;
    height: 94px;

    .btn {
      height: 40px;
      padding: 0 19px;
      box-sizing: border-box;
      background: #11aa8c;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        height: 12px;
        width: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>
