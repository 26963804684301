import request from '@/utils/request'
// 新增聊天记录
export function commitPaper(data) {
  return request({
    url: 'exam/stu/commitPaper',
    method: 'post',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// 新增聊天记录
export function myOrders(data) {
  return request({
    url: '/stu/order/myOrders',
    method: 'post',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// 新增聊天记录
export function toPay(data) {
  return request({
    url: '/payOrder/toPay',
    method: 'post',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// 修改个人信息
export function update(data) {
  return request({
    url: '/stu/center/stuInfo/update',
    method: 'put',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// 开始做题
export function studyRecord(data) {
  return request({
    url: 'stu/center/studyRecord' ,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// examRecord
export function examRecord(data) {
  return request({
    url: '/stu/center/examRecord' ,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// examRecord
export function canGetCardCourses(data) {
  return request({
    url: '/stu/center/canGetCardCourses' ,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
export function applyCard(data) {
  return request({
    url: '/stu/center/applyCard' + '/' + data,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// 个人信息
export function stuInfo(data) {
  return request({
    url: '/stu/center/stuInfo' ,
    method: 'get',
    headers : {"authorization":localStorage.getItem('GFtoken')}

  })
}
// 修改密码
export function updatePass(data) {
  return request({
    url: '/updatePass',
    method: 'put',
    data: data,
    headers : {"authorization":localStorage.getItem('GFtoken')}
  })
}
// // 开始做题
// export function studyRecord(data) {
//   return request({
//     url: 'stu/center/studyRecord' + '/' + data,
//     method: 'get',
//     headers : {"authorization":localStorage.getItem('GFtoken')}

//   })
// }
